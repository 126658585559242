import DataTable from 'react-data-table-component';
import styles from "./styles";
import React, {useState} from "react";
import PropTypes from "prop-types";
import userModel from "../../../../assets/data/user";
import azure from "../../../../utils/azure";
import Api from "../../../../Api/Api";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import Icon from "@mui/material/Icon";
import colors from "../../../../colors";
import language from "../../../../assets/data/language";
import UploadModal from "./uploadModal";
import GenerateCard from "./generateCard";
import Notification from "./notifications"

const ExpandedComponent = ({data}) => {
  console.log(data, "DATA")
  return <div style={styles.expandable}>

  </div>;
}

ExpandedComponent.propTypes = {
  data: PropTypes.object.isRequired
};


function Certificates({user, spanish}) {
  const [userData, setUserData] = useState(false);
  const [quizzes, setQuizzes] = useState(false);
  const [otherUploadQuiz, setOtherUploadQuiz] = useState(false);
  const [otherUploadQuizSource, setOtherUploadQuizSource] = useState(false);
  const [uploadQuiz, setUploadQuiz] = useState('');
  const [uploadIssued, setUploadIssued] = useState('');
  const [uploadExpiration, setUploadExpiration] = useState('');
  const [certUploader, setCertUploader] = useState(false);
  const [fileMessage, setFileMsg] = useState(false);
  const [cardGenerated, setGenerateCard] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const {fetchCertificates} = azure;
  const fetchControl = React.useRef(false);
  const fetchControlQuiz = React.useRef(false);

  const fetchQuizData = async () => {
    const quizData = await Api.getAllQuizzes().then(quizData => quizData.data).catch(err =>console.log(err))
    setQuizzes(quizData)
    return quizData;
  }

  if(!quizzes && !fetchControlQuiz.current) {
    fetchControlQuiz.current = true;
    fetchQuizData()
  }

  const getExpiration = (row) => {
    try {
      if(row.expiration) return row.expiration
      else {
        const quiz = quizzes.filter(q => q.id === row.certificate?.metadata?.quiz);
        if(quiz[0]) {
          const taken = new Date(row.date);
          let yearsToAdd;
          let frequency = quiz[0].quizFrequency;
          if(frequency === "Annual") yearsToAdd = 1;
          else if(frequency === "ThreeYears") yearsToAdd = 3;
          else yearsToAdd = 2;
          const expiration = dayjs(taken.setFullYear(taken.getFullYear() + yearsToAdd)).format('MM/DD/YYYY');
          return expiration
        } else return ""
      }
    } catch (e) {
      return ""
    }

  }
  const getCertificates = async () => {
    fetchControl.current = true;
    console.log(user, "USER")
    const certificatesUser = await fetchCertificates(user.UserID).then(certs => certs).catch(err => console.log(err));
    console.log(certificatesUser," CERTIVCA")
    const certificatesEmail = await fetchCertificates(user.Email).then(certs => certs).catch(err => console.log(err));
    let certificates = [ ...certificatesUser]
    if(Array.isArray(certificatesEmail)) certificates = [...certificates, ...certificatesEmail]
    const oldest = {}
    const latestToKeep = {}
    let rows = certificates.map(certificate => {
      let count = certificate.name.split("-").length - 1;
      let name = certificate.name.split("/")[1];
      if(count > 1) name = certificate.name.split("/")[1]
      const row = {
        name,
        path: certificate.name,
        quiz: certificate.metadata.quizName || certificate.metadata.videoName,
        date: certificate.metadata.issued ? certificate.metadata.issued : "",
        expiration: certificate.metadata.expiration ? certificate.metadata.expiration : "",
        certificate,
      }
      if (oldest[row.quiz]) oldest[row.quiz].push(certificate.metadata.issued)
      else oldest[row.quiz] = [certificate.metadata.issued]
      return row
    })
    for(let k in Object.keys(oldest)) {
      let tag = Object.keys(oldest)[k];
      const oldestDate = oldest[tag].sort((a, b) => new Date(a) - new Date(b))[oldest[tag].length - 1];
      latestToKeep[tag] = oldestDate;
    }
    rows = rows.filter(a => {
      if(!latestToKeep[a.quiz] || latestToKeep[a.quiz] === a.date) return a;
    })
    setUserData(rows)
  }

  if (!userData && !fetchControl.current) getCertificates()

  const closeModal = (e) => {
    setUploadIssued('');
    setUploadQuiz('');
    setCertUploader(false);
    setOtherUploadQuiz(false);
    setUploadExpiration(false);
    setOtherUploadQuizSource(false);
  }

  const uploadCert = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    console.log(file, file.type)
    if (file && ["application/pdf", "image/jpeg"].includes(file.type)) {
      if (file.size > 1500000) setFileMsg(language.common.fileLarge(spanish));
      else {
        let file_name = file.name;
        let quiz = quizzes.filter(q => q.id === uploadQuiz);
        const meta = {
          quiz: uploadQuiz || "",
          issued: dayjs(uploadIssued).format('MM/DD/YYYY'),
          expiration: uploadExpiration ? dayjs(uploadExpiration).format('MM/DD/YYYY') : "",
          quizName: quiz[0]?.english?.title || otherUploadQuizSource
        }
        azure.uploadCert(file, `${user.UserID}/${file_name}`, meta).then(saved => {
          setUserData(false);
          fetchControl.current = false;
          setCertUploader(false);
          getCertificates();

        }).catch(err => console.log(err));
        // setProfileFile(img);
      }
    } else setFileMsg(language.certificates.fileWrong(spanish))
  }

  const deleteCert = async (e, file) => {
    await azure.deleteCert(file.certificate.name).then(deleted => console.log(deleted)).catch(err => console.log(err));
    await getCertificates();
  }



  const columns = [
    {
      name: language.certificates.name(spanish),
      selector: row => row.name,
      sortable: true
    },
    {
      name: language.certificates.issued(spanish),
      selector: row => row.date,
      sortable: true
    },
    {
      name: language.certificates.expiration(spanish),
      selector: row => getExpiration(row),
      sortable: true
    },
    {
      name: language.certificates.quiz(spanish),
      selector: row => row.quiz,
      sortable: true
    },
    {
      name: language.certificates.manual(spanish),
      selector: row => row.certificate.manualUpload ? language.common.yes(spanish) : language.common.no(spanish),
      sortable: true
    },
    {
      name: language.certificates.download(spanish),
      selector: row => <button
        style={{...styles.btn, backgroundColor: "transparent", color: colors.MARATHON.primary}}
        onClick={(e) => window.open(row.certificate.url, '_blank')}
      ><Icon fontSize="medium">download</Icon></button>
    },
    {
      name: language.certificates.delete(spanish),
      selector: row => <button
        style={{...styles.btn, backgroundColor: "transparent", color: colors.MARATHON.primary}}
        onClick={(e) => deleteCert(e, row)}
      >
        <Icon fontSize="medium">delete</Icon>
      </button>
    }
  ];


  const filteredItems = (userData && filterText != "") ? userData.filter(
    item => {
      const enabled = ['name', 'date']
      const possible_rows = Object.keys(item);
      let toReturn = false;
      for (let row in possible_rows) {
        const key = possible_rows[row]
        if (enabled.includes(key)) {
          let value = item[key]
          if (['CompanyID', 'JobClassification'].includes(key)) {
            let model = userModel.companyId;
            if (key == "JobClassification") model = userModel.classification;
            value = model[value - 1]
          }
          if (value && value !== null && value.toString().toLowerCase().includes(filterText.toLowerCase())) toReturn = item;
        }
      }
      return toReturn
    }
  ) : userData;


  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <div style={styles.toolbar}>
        <input
          style={{
            transition: 'box-shadow 0.2s ease 0s',
            padding: 8,
            border: '1px solid rgba(0, 22, 66, 0.32)',
            background: 'rgb(246, 249, 250)',
            outline: 'none',
            borderRadius: 2
          }}
          autoComplete="off"
          placeholder={language.common.search(spanish)}
          onChange={e => setFilterText(e.target.value)}
          value={filterText}/>
        <Button variant={"contained"} onClick={(e) => {
          // fetchQuizData()
          setCertUploader(true)
        }}>{language.common.upload(spanish)}</Button>

      </div>


    );
  }, [filterText, resetPaginationToggle]);

  const handleUploadQuiz = (e) => {
    const value = e.target.value;
    console.log(value, "VALUE")
    if(value === "clear"){
      setOtherUploadQuiz(false);
      setOtherUploadQuizSource("");
      setUploadQuiz("");
    } else if(value !== "Other") {
      if(otherUploadQuiz) {
        setOtherUploadQuiz(false);
        setOtherUploadQuizSource("");
      }
      setUploadQuiz(e.target.value)
    }
    else setOtherUploadQuiz(true)

  }
  const generateCard = (e) => {
    console.log("generates")
    setGenerateCard(true);
  }

  return (
    <div style={styles.container}>
      <UploadModal
        certUploader={certUploader}
        closeModal={closeModal}
        spanish={spanish}
        handleUploadQuiz={handleUploadQuiz}
        quizzes={quizzes}
        otherUploadQuiz={otherUploadQuiz}
        otherUploadQuizSource={otherUploadQuizSource}
        setOtherUploadQuizSource={setOtherUploadQuizSource}
        fileMessage={fileMessage}
        uploadCert={uploadCert}
        uploadQuiz={uploadQuiz}
        uploadIssued={uploadIssued}
        setUploadIssued={setUploadIssued}
        setUploadExpiration={setUploadExpiration}
        uploadExpiration={uploadExpiration}
      />
      {userData && <DataTable
        title={language.certificates.certificates(spanish)}
        columns={columns}
        data={filteredItems}
        // expandableRows
        // selectableRows
        pagination
        // dense
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        highlightOnHover
        pointerOnHover
        expandableRowsComponent={ExpandedComponent}
      />}
      {(userData && userData.length > 0) && <Button
        onClick={generateCard}
        variant="contained"
        style={{marginTop: 20}}
      >
        {language.certificates.generate(spanish)}
      </Button>}
      <GenerateCard
        spanish={spanish}
        user={user}
        quizzes={quizzes}
        closeModal={e => setGenerateCard(false)}
        modalOpen={cardGenerated}
        userData={userData}
      />
      <Notification
        userData={userData}
        spanish={spanish}
        quizzes={quizzes}
        user={user}
      />
    </div>

  );
}

Certificates.propTypes = {
  user: PropTypes.object,
  spanish: PropTypes.bool,
}

export default Certificates;
